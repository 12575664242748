import { authHeader } from './auth-header';
import { reactive } from 'vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const userDetails = reactive({
    user: {}
})
export const userService = {
    login,
    logout,
    register,
    getAll,
    changePassword,
    getsuperadmin,
    updateSuperAdmin
};

function login(email, password, isRemembered) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(import.meta.env.VITE_API_URL + `login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.access_token) {
                if (isRemembered === true) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user.result));
                    //Token Store
                    localStorage.setItem('token', JSON.stringify(user.access_token));
                } else {
                    localStorage.setItem('user', JSON.stringify(user.result));
                    //Token Store
                    localStorage.setItem('token', JSON.stringify(user.access_token));
                    sessionStorage.setItem('token', JSON.stringify(user.access_token));
                    sessionStorage.setItem('user', JSON.stringify(user.result));
                }

            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`/logout`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function changePassword(oldpassword, newpassword) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ oldpassword, newpassword })

    };
    return fetch(import.meta.env.VITE_API_URL + `changePassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function getsuperadmin() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(import.meta.env.VITE_API_URL + `superadmin`, requestOptions)
        .then(handleResponse)
        .then(result => {
            console.log("service", result);
            return result;
        });

}
function updateSuperAdmin(id, name) {
    console.log("updateSuperAdmin", name)
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ name })

    };
    return fetch(import.meta.env.VITE_API_URL + `superadmin/` + id, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            if (response.status == 400) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status === 422) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (response.status === 401) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            else if (response.status === 500) {
                toast.error("Internal Server Error", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
