import store from '../state/store';
import { defineAsyncComponent, ref } from 'vue';

export default [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/account/login.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('authfack/logout');

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                );
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom });
            },
        },
    },
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index.vue')
    },

    {
        path: '/doctor/register/',
        name: 'Register',
        component: defineAsyncComponent(() => import('../views/pages/account/register.vue')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                //   If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/doctor/login/',
        name: 'DoctorLogin',
        component: defineAsyncComponent(() => import('../views/pages/account/doctor-login.vue')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/doctor/logout',
        name: 'DoctorLogout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('doctorauth/logout');

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/doctor/login')
                );
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'DoctorDashboard' } : { ...routeFrom });
            },
        },
    },
    {
        path: '/doctor/dashboard',
        name: 'DoctorDashboard',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index.vue')
    },
    {
        path: '/doctor/clinic',
        name: 'Doctor Clinic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/clinic/index.vue')
    },
    {
        path: '/doctor/patient',
        name: 'Doctor Patient',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/patient/index.vue')
    },
    {
        path: '/doctor/appointment',
        name: 'Doctor Appointment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/appointment/index.vue')
    },
    {
        path: '/doctor/appointment/add/:appointmentID',
        name: 'Add Appointment',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/appointment/appointment-add.vue'),
        props: true
    },
    {
        path: '/doctor/medicine',
        name: 'Doctor Add Medicine',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/medicine/index.vue'),
        props: true
    },
    {
        path: '/doctor/change-password',
        name: 'Doctor Change Password',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/change-password.vue')
    },
    {
        path: '/doctor/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/index.vue')
    },

];
